import isNil from "lodash/isNil";
import { getMhcWeekSeries } from "graphqlApi/legacy/mhcClient";

import { MhcTimeSeriesGranularityEnum, MhcWeekSeries } from "graphqlApi/types";
import { StatIdConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { getDataDatePeriod } from "../../elementHelpers/Covid/util";
import { dateToIsoString } from "common/util/date";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { KpiProps } from "common/components/KPI";
import { createKpiProps } from "./createKpiProps";

interface FetchFourWeekAverageKpisProps {
  statIds?: StatIdConfig[];
  locationId: string;
  locationName: string;
}

const calculatePercentageChange = (
  originalValue?: number | null,
  newValue?: number | null
): number | null => {
  if (isNil(originalValue) || isNil(newValue)) return null;
  const delta = originalValue - newValue;
  return (delta / originalValue) * 100;
};

const getLastFiveInPairs = (array: (number | null)[]) => {
  const lastFive = array.slice(-5);
  const pairs = [];
  for (let i = 0; i < lastFive.length - 1; i += 1) {
    pairs.push(lastFive.slice(i, i + 2));
  }
  return pairs;
};

const calculateAverage = (array: (number | null)[]): number | null => {
  return array.reduce<number>((acc: number, val) => acc + (val ?? 0), 0) / array.length;
};

const getFourWeekAverageFromWeekSeries = (weekSeries: MhcWeekSeries) => {
  const currentDate = new Date();
  const currentIndex = weekSeries.timestamps.findIndex((timestamp) => {
    const start = getUtcDateFromString(timestamp.startDate);
    const end = getUtcDateFromString(timestamp.endDate);
    if (isNil(start) || isNil(end)) return false;
    return start >= currentDate && currentDate <= end;
  });
  if (currentIndex === -1) {
    const lastFiveValuePairs = getLastFiveInPairs(weekSeries.values);
    const lastFivePercentageChange = lastFiveValuePairs.map(([start, end]) =>
      calculatePercentageChange(start, end)
    );
    const average = calculateAverage(lastFivePercentageChange);
    return average ?? null;
  }
  const slicedArray = weekSeries.values.slice(0, currentIndex);
  const lastFiveValuePairs = getLastFiveInPairs(slicedArray);
  const lastFivePercentageChange = lastFiveValuePairs.map(([start, end]) =>
    calculatePercentageChange(start, end)
  );
  const average = calculateAverage(lastFivePercentageChange);
  return average ?? null;
};

export const fetchFourWeekAverageKpis = async ({
  statIds,
  locationId,
  locationName
}: FetchFourWeekAverageKpisProps) => {
  if (isNil(statIds) || statIds?.length === 0)
    return { kpis: [], latestDate: null, granularity: null };

  const locationStats = await Promise.all(
    statIds.map(
      async (stat) =>
        await getMhcWeekSeries({
          locationId,
          statId: typeof stat === "string" ? stat : stat.identifier,
          granularity: "week"
        })
    )
  );
  const kpis = await Promise.all(
    locationStats.map(async (locationStat) => {
      const weekSeries = locationStat.weekSeries;
      let fourWeekAverageValue: number | null = null;
      if (weekSeries.timestamps.length > 0) {
        fourWeekAverageValue = getFourWeekAverageFromWeekSeries(weekSeries) ?? null;
      }
      const kpi: KpiProps = await createKpiProps({
        locationId,
        ...locationStat,
        options: {
          includeTrend: false,
          enhancements: {
            geography: locationName
          }
        }
      });
      const title = (
        typeof kpi.title === "string" ? kpi.title.replace("(this season)", "") : ""
      ).replace("this season", "This Season");
      kpi.title = `${title ?? ""} (Average of Last 4 Weeks)`;
      const lastUpdatedDate = getUtcDateFromString(locationStat.lastUpdatedOn);
      let dataDatePeriod = "";
      if (!isNil(lastUpdatedDate)) {
        const startDate = new Date(lastUpdatedDate);
        startDate.setDate(startDate.getDate() - (7 * 4 + 6));
        dataDatePeriod = getDataDatePeriod({
          start: dateToIsoString(startDate),
          end: dateToIsoString(lastUpdatedDate),
          granularity: MhcTimeSeriesGranularityEnum.Week
        });
      }
      if (dataDatePeriod) {
        kpi.enhancement = {
          ...kpi.enhancement,
          dataDatePeriod
        };
      }
      kpi.value =
        fourWeekAverageValue &&
        (fourWeekAverageValue > 0
          ? "Increasing"
          : fourWeekAverageValue < 0
          ? "Decreasing"
          : "No Change");
      kpi.additionalInformation =
        "This change is calculated by taking the percentage change from the previous 4 weeks and averaging them together.";
      return kpi;
    })
  );
  return { kpis, latestDate: null, granularity: null };
};
