import { MhcCategoryFragment, MhcPageFragment, MhcTopicCategoryEnum } from "graphqlApi/types";

import { logWarning } from "common/util/consoleHelpers";
import { localTopics } from "common/util/topics";

import { MhcTopicCategories } from "layout/SideNav";
import topicsWithSections from "modules/Topics/pages/topicsWithSections";

const updateHrefForTopic = (topic: MhcPageFragment, locationId: string) => {
  return { ...topic, href: topic.href.replace("state", locationId) };
};

const updateHref = (topics: MhcPageFragment[], locationId: string): MhcPageFragment[] => {
  return topics.map((topic: MhcPageFragment) => ({
    ...updateHrefForTopic(topic, locationId),
    children: topic.children ? updateHref(topic.children as MhcPageFragment[], locationId) : []
  }));
};

interface Props {
  locationId?: string;
  pages: MhcPageFragment[];
  categories: MhcCategoryFragment[];
  allowedCategories: MhcTopicCategoryEnum[];
}

export const processTopics = ({
  locationId = "state",
  pages: _pages,
  categories,
  allowedCategories
}: Props): MhcTopicCategories => {
  const pages = [...Object.values(localTopics), ..._pages];

  const navItems: MhcTopicCategories = [...categories]
    .sort(
      (a, b) =>
        allowedCategories.indexOf(a.id as MhcTopicCategoryEnum) -
        allowedCategories.indexOf(b.id as MhcTopicCategoryEnum)
    )
    .filter((cat) => (allowedCategories as string[]).includes(cat.id) || cat.id === "all_pages")
    .map((cat) => ({ ...cat, children: pages.filter((t) => t.category === cat.id) }))
    .reduce((acc, cat) => ({ ...acc, [cat.id]: cat }), {} as MhcTopicCategories);

  topicsWithSections.forEach(({ id, parentId, category, sections }) => {
    if (!category || !navItems[category] || navItems[category] === undefined) return;
    const categoryItems = navItems[category];
    if (categoryItems === undefined || !("children" in categoryItems)) return;

    const children = sections(locationId);
    const _id = parentId ?? id;
    let item = categoryItems.children.find(({ slug }) => slug === _id);
    if (!item) return;

    if (parentId && item.children?.length) {
      const childItem = item.children.find(({ slug }) => slug === id) as MhcPageFragment;
      const childIndex = item.children.indexOf(childItem) as number;
      const parentIndex = categoryItems.children.indexOf(item);
      const parentChildren = [...(item.children as MhcPageFragment[])];
      parentChildren[childIndex] = { ...childItem, children };
      try {
        item = { ...item, children: [...parentChildren] };
      } catch (error) {
        logWarning(`Could not modify nav item object ${item && item.name} ${error as string}`);
      }
      categoryItems.children[parentIndex] = {
        ...item,
        children: parentChildren
      };
      return;
    }

    const index = categoryItems.children.indexOf(item);
    const topic = (categoryItems.children[index] as MhcPageFragment) ?? { children: [] };
    const _children = [...(topic.children as MhcPageFragment[])];
    if (_children.length > 0) {
      _children.unshift({
        ...topic,
        name: "Overview",
        href: topic.href.replace(locationId, `overview/${locationId}`),
        children
      });
      categoryItems.children[index] = {
        ...topic,
        children: _children
      };
    } else {
      categoryItems.children[index] = {
        ...topic,
        children: children
      };
    }
  });

  Object.entries(navItems).forEach(([key, category]) => {
    navItems[key] = {
      ...category,
      children: updateHref(category.children, locationId)
    };
  });

  return navItems;
};
