import { MhcLocation, MhcLocationFragment } from "graphqlApi/types";

import { ContentCard } from "layout/card/ContentCard";
import { TopicMedia } from "modules/Topics/pages/items/AsthmaEdVisits";
import { TopicVideo } from "./TopicVideo";

export interface TopicVideosProps {
  media: TopicMedia;
  location?: MhcLocation | MhcLocationFragment;
}

// TODO: We probably want to deprecate this component in favor of
// src/app/topics/[topicId]/[locationId]/components/TopicVideosSC.tsx
export const TopicVideos: React.FC<TopicVideosProps> = ({ media, location }) => {
  if (media.length === 0) return null;
  return (
    <ContentCard title="Investigate Maps">
      {media.map(({ videos, statIdentifier }) =>
        videos?.map((video, i) => (
          <TopicVideo
            {...video}
            location={location}
            statIdentifier={statIdentifier}
            key={statIdentifier?.id ?? i}
          />
        ))
      )}
    </ContentCard>
  );
};
